import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import Button from "../components/atoms/button";
import DesignMenu from "./design-menu.json";

function DesignMiscUI() {
  const miscUIMenu = DesignMenu.find((dm) => dm.slug === "misc-ui");
  return (
    <div className="design__content">
      <SEO title="Design System - Misc. UI" />
      <h2>Misc. UI</h2>
      <p className="design__text p2">
        Miscellaneous UI includes badges, product option buttons, color
        swatches, form inputs, checkboxes and dropdowns.
      </p>
      {miscUIMenu.submenu.map((item) => (
        <Button
          className="shared__link"
          route={`/${item.slug}`}
          key={item.slug}>
          {item.title}
        </Button>
      ))}
    </div>
  );
}

export default DesignMiscUI;
